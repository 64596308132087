// @flow

export default [
  {
    classID: '#authPartner',
    events: [{ eventName: 'mouseenter', frames: [85, 115] }],
    closeFrames: [115, 140],
  },
  {
    classID: '#appDevelop',
    events: [{ eventName: 'mouseenter', frames: [140, 170] }],
    closeFrames: [170, 195],
  },
  {
    classID: '#specialist',
    events: [{ eventName: 'mouseenter', frames: [195, 230] }],
    closeFrames: [230, 260],
  },
  {
    classID: '#profServices',
    events: [{ eventName: 'mouseenter', frames: [260, 285] }],
    closeFrames: [285, 318],
  },
  {
    classID: '#center',
    events: [{ eventName: 'mouseenter', frames: [320, 350] }],
    closeFrames: [350, 380],
  },
];

export const partners = [
  {
    classID: '#Pico',
    link: 'https://invelon.com/pico/',
  },
  {
    classID: '#Microsoft',
    link: 'https://invelon.com/microsoft/',
  },
  {
    classID: '#PTC',
    link: 'https://invelon.com/ptc/',
  },
  {
    classID: '#HP',
    link: 'https://invelon.com/hp/',
  },
  {
    classID: '#Lenovo',
    link: 'https://invelon.com/lenovo/',
  },
  {
    classID: '#HTC',
    link: 'https://invelon.com/htc-vive/',
  },
  {
    classID: '#Oculus',
    link: 'https://invelon.com/oculus-business/',
  },
];
