/* eslint-disable no-use-before-define */
// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import flow from 'lodash/fp/flow';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQueryTheme';
import classnames from 'classnames';
import Lottie from 'react-lottie';
import compact from 'lodash/compact';

import * as animationData from './ecosystem.json';
import * as mobileAnimationData from './mobileEcosystem';
import getListOfElementsById from './helpers/getListOfElementsByID';
import animationEcosystem, { partners } from './helpers/animationEcosystem';
import addEventsListeners from './helpers/addEventsListeners';
import addPartnersLinks from './helpers/addPartnersLinks';
import playInitialFrames from './helpers/playInitialFrames';

export type ClassKey = 'root';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = unusedTheme => ({
  root: {},
});

const DiagramAnimation = ({
  className,
  classes,
  ...props
}: Props): React.Node => {
  const lottieRef = React.useRef(null);

  const theme = useTheme<Theme>();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const deviceRef = React.useRef(isSmDown ? 'mobile' : 'desktop');
  const openClassIdRef = React.useRef(null);

  const handleAnimationChange = React.useCallback((frames, classID) => {
    const { anim } = lottieRef.current || {};
    if (anim) {
      const closeFrames =
        openClassIdRef.current && openClassIdRef.current !== classID
          ? animationEcosystem.find(
              animationPart => animationPart.classID === openClassIdRef.current,
            )?.closeFrames
          : undefined;
      anim.playSegments(compact([closeFrames, frames]), true);
      openClassIdRef.current = classID;
    }
  }, []);

  const onLoaded = React.useCallback(() => {
    const { anim, el } = lottieRef.current || {};
    const observer = new IntersectionObserver(() => {
      playInitialFrames({ device: deviceRef.current || 'desktop', anim });
    });
    observer.observe(el);

    if (anim) {
      flow(
        addPartnersLinks,
        getListOfElementsById,
        addEventsListeners,
      )({
        element: el || undefined,
        partners,
        animationEcosystem,
        handleAnimationChange,
      });
    }
  }, [handleAnimationChange]);

  React.useLayoutEffect(() => {
    deviceRef.current = isSmDown ? 'mobile' : 'desktop';
  }, [isSmDown]);

  return (
    <Lottie
      ref={lottieRef}
      options={{
        renderer: 'svg',
        animationData: isSmDown
          ? mobileAnimationData.default
          : animationData.default,
        loop: false,
        autoplay: false,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      eventListeners={[
        {
          eventName: 'DOMLoaded',
          callback: onLoaded,
        },
      ]}
      className={classnames(classes.root, className)}
      {...props}
    />
  );
};

DiagramAnimation.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'DiagramAnimation' })(DiagramAnimation);
