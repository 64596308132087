// @flow
import * as React from 'react';

export type InitialFrames = {|
  device: string,
  anim: any,
|};

export default function playInitialFrames({ device, anim }: InitialFrames) {
  if (anim) {
    return {
      mobile: () => {
        anim.playSegments(
          [
            [0, 20],
            [85, 140],
            [195, 260],
            [260, 318],
            [140, 195],
            [85, 115],
          ],
          true,
        );
      },
      desktop: () => {
        anim.playSegments([320, 350], true);
        setTimeout(() => {
          anim.playSegments([85, 115], true);
        }, 2000);
      },
    }[device]();
  }
  return {};
}
