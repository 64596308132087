// @flow

import type { HTMLElements } from '../types';

export default function addEventsListeners(
  htmlElements: HTMLElements,
): HTMLElements {
  return htmlElements.map(({ element, events, ...other }) => ({
    ...other,
    element,
    events: events.map(event => {
      if (element) {
        element.addEventListener(event.eventName, event.handleAnimationChange);
      }
      return event;
    }),
  }));
}
