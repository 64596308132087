// @flow
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/react-in-jsx-scope */
import * as React from 'react';
import { navigate } from 'gatsby';
import ReactDOM from 'react-dom';
import { type Config } from './getListOfElementsByID';

export type PartnersLinks = {
  ...$Exact<Config>,
  partners: Array<{
    classID: string,
    link: string,
  }>,
};

export default function addPartnersLinks({
  element,
  partners,
  ...props
}: PartnersLinks): Config {
  partners.map(({ classID, link }) => {
    const htmlElement = (element || document).querySelector(classID);
    const imageChild = htmlElement && htmlElement.firstElementChild;

    if (imageChild) {
      imageChild.addEventListener('click', () => {
        navigate(link);
      });
      imageChild.addEventListener('mouseover', () => {
        // $FlowFixMe --> Reason: we know imageChild is an HTMLElement
        imageChild.style.transform = 'scale(1.1)';
      });
      imageChild.addEventListener('mouseout', () => {
        // $FlowFixMe --> Reason: we know imageChild is an HTMLElement
        imageChild.style.transform = 'scale(1)';
      });
      // $FlowFixMe --> Reason: we know imageChild is an HTMLElement
      imageChild.style.cursor = 'pointer';
    }

    return imageChild;
  });
  return {
    element,
    ...props,
  };
}
