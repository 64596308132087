// @flow

import type { AnimationEcosystem, HTMLElements } from '../types';

export type Config = {|
  element?: ?HTMLElement,
  animationEcosystem: AnimationEcosystem,
  handleAnimationChange: (Array<number>, string) => void,
|};

export default function getListOfElementsByID({
  element,
  animationEcosystem,
  handleAnimationChange,
}: Config): HTMLElements {
  return animationEcosystem.map(({ classID, events, ...other }) => ({
    element: (element || document).querySelector(classID) || null,
    events: events.map(event => ({
      ...event,
      handleAnimationChange: () => handleAnimationChange(event.frames, classID),
    })),
    ...other,
  }));
}
